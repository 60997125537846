<template>
  <div class="c-shopping-cart__vendors-toolbar u-flex u-flex-align-center">
    <button
      v-if="selectedVendor
        && selectedVendor.name"
      class="c-shopping-cart__vendors-button u-flex u-flex-direction-column u-flex-align-center u-mr--s"
      :class="{
        'u-flex-direction-column': $mq != 'desktop'
      }"
      @click="store.state.isModalVendorSelectorVisible = true"
    >
      <span class="u-flex u-flex-align-center">
        <Picture
          class="c-shopping-cart__vendors-toolbar-image u-flex-shrink-0"
          :src="selectedVendor.logo"
          :resizer="selectedVendor.imageResizer.name"
          :alt="selectedVendor.title"
          :mobile="[100, 50]"
          :options="{ mode : 'max'}"
        />
        <Icon
          v-if="$mq == 'desktop'"
          class="u-flex-shrink-0 u-ml--xxs"
          :icon="iconArrow"
          :size="12"
          fill="medium-gray"
        />
      </span>
      <span
        v-if="$mq != 'desktop'"
        class="u-font-size-medium u-text-underline u-text-medium-gray"
      >{{ changeVendorText }}</span>
      <span
        v-if="store.state.selectedVendor.userPostalCodeRequired && store.state.locality"
        class="u-text-black u-font-size-medium u-text-underline u-font-bold"
      >
        {{ store.state.postalCode }} {{ store.state.locality }}
      </span>
    </button>

    <button
      class="c-button c-button--dark c-button--green"
      :class="{
        'u-ml--a': $mq !== 'desktop'
      }"
      @click.prevent="goToVendorSite()"
    >
      <Loader
        v-if="isLoading || matchedCountLoading"
        class="c-shopping-list__checkout-loader"
      />
      <span
        class="c-shopping-list__checkout-text"
        :style="{
          opacity: isLoading || matchedCountLoading ? 0 : null
        }"
      >
        {{ checkoutText }}
        <span
          v-if="store.state.matchedCount && totalCount"
          :style="{
            opacity: isLoading || matchedCountLoading ? 0.5 : 1
          }"
        >
          ({{ store.state.matchedCount }}/{{ totalCount }})
        </span>
      </span>
    </button>
  </div>
</template>

<script>
import iconArrow from '@ds/svg/icons/bold/arrow-down-1-bold.svg'
import Picture from '@/CVI/WebCore/components/Picture.vue'
import store from '@/CVI/Purchase/store'
import Loader from '@/CVI/WebCore/components/Loader.vue'
import api from '@/CVI/Purchase/lib/api'
import tracker from '@/CVI/WebCore/core-tracker'
import Icon from '@/CVI/WebCore/components/Icon.vue'

export default {
  components: {
    Picture,
    Loader,
    Icon
  },
  props: {
    checkoutText: {
      type: String,
      default: ''
    },
    changeVendorText: {
      type: String,
      default: ''
    },
    totalCount: {
      type: Number,
      default: 0
    },
    matchedCountLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      store,
      iconArrow,
      isLoading: false
    }
  },
  computed: {
    selectedVendor() {
      return this.store.state.selectedVendor
    },
    recipePortions() {
      return this.store.state.cart.map(({ uid, portionCount }) => {
        return {
          recipeId: uid,
          portions: portionCount
        }
      })
    }
  },
  mounted() {
    const { selectedVendor } = this.store.state
    if (selectedVendor) {
      this.store.state.preselectedVendor = selectedVendor
    }
  },
  methods: {
    trackCheckout() {
      const { state } = this.store

      tracker.trackCheckoutOption({
        vendor: state.selectedVendor.id,
        vendorRank: state.vendors.indexOf(state.selectedVendor) + 1,
        products: this.store.getters.trackingRecipes
      })
    },
    async goToVendorSite() {
      this.isLoading = true
      const { id, userPostalCodeRequired } = this.selectedVendor
      const { recipePortions } = this
      this.trackCheckout()
      const win = window.open()
      const { shoppingCartId } = this.store.state.selectedVendor
      let vendorSiteUrl

      if (shoppingCartId) {
        const { url } = (await api.shoppingСart.postDraft(shoppingCartId)).data
        vendorSiteUrl = url
      } else {
        const requestObj = {
          vendorName: id,
          recipePortions
        }
        if (userPostalCodeRequired) {
          requestObj.postalCode = this.store.state.postalCode
        }
        const { url } = (await api.shoppingСart.post(requestObj)).data
        vendorSiteUrl = url
      }
      this.store.dispatch('clearOneTimeVendor')
      win.location = vendorSiteUrl
      win.focus()
      this.isLoading = false
    }
  }
}
</script>
