<template>
  <div>
    <template v-if="!store.state.isMealplanEnabled">
      <div
        v-for="recipe in recipeList"
        :key="recipe.id"
        class="u-mb--m"
      >
        <RecipeListItem
          :key="recipe.id"
          :recipe="recipe"
          :basic-ingredients-heading="basicIngredientsHeading"
        />
      </div>
    </template>
    <template v-else>
      <div
        v-for="recipe in unsortedRecipes"
        :key="recipe.id"
        class="u-mb--s"
      >
        <RecipeListItem
          :key="recipe.id"
          :recipe="recipe"
          :basic-ingredients-heading="basicIngredientsHeading"
        />
      </div>
      <div
        v-for="day in mealplanDays"
        :key="day.weekday"
        class="u-mb--m"
      >
        <h3 class="c-shopping-cart__mealplan-day">
          {{ day.weekday }}
        </h3>
        <div
          v-if="!day.recipes.length"
          class="c-shopping-cart__mealplan-empty-day u-flex u-flex-align-center"
          @click="openShoppingPanel"
        >
          <span
            v-if="searchRecipesButtonText"
            class="u-ml--s"
          >
            {{  searchRecipesButtonText }}
          </span>
        </div>
        <div
          v-for="recipe in day.recipes"
          :key="recipe.id"
          class="u-mb--s"
        >
          <RecipeListItem
            :key="recipe.id"
            :recipe="recipe"
            :basic-ingredients-heading="basicIngredientsHeading"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>

import store from '@/CVI/Purchase/store'
import RecipeListItem from '@/CVI/Purchase/components/RecipeListItem.vue'
import webcoreStore from '@/CVI/WebCore/store'

export default {
  components: {
    RecipeListItem
  },
  props: {
    recipeList: {
      type: Array,
      required: true
    },
    basicIngredientsHeading: {
      type: String,
      default: ''
    },
    weekdays: {
      type: Array,
      required: true
    },
    searchRecipesButtonText: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      store,
      webcoreStore
    }
  },
  computed: {
    unsortedRecipes() {
      const { mealplanDays, recipeList } = this
      let mealplanRecipes = []
      mealplanDays.forEach(({ recipes }) => {
        mealplanRecipes = [...mealplanRecipes, ...recipes]
      })

      if (mealplanRecipes.filter(item => item).length) {
        return recipeList.filter(({ id }) => (!mealplanRecipes.find(mealplanRecipe => id == mealplanRecipe.id)))
      }
      return recipeList
    },
    mealplanDays() {
      const { mealplan, recipeList } = this.store.state
      const { weekdays } = this

      return weekdays
        .map((weekday) => {
          const recipeUids = mealplan.filter(({ day }) => day === weekday).map(({ uid }) => uid)
          const recipes = recipeUids.map(uid => (recipeList.find(({ id }) => `rdb:${id}` == uid)))

          return { weekday, recipes }
        })
    }
  },
  methods: {
    openShoppingPanel() {
      this.webcoreStore.state.isShoppingPanelVisible = true
    }
  }
}
</script>
