<template>
  <div
    class="u-flex u-flex-align-center"
    :class="{
      'c-product-line__loading': isLoading
    }"
  >
    <div class="c-product-line__image-container u-flex-shrink-0 u-mr--s">
      <!-- onload and onerror handles 404 images -->
      <img
        class="c-product-line__image"
        :src="product.imageUrl"
        onload="this.style.display=''"
        onerror="this.onerror = null; this.style.display='none'"
      >
      <Icon
        class="c-product-line__missing-icon"
        :icon="missingIcon"
        :size="32"
        stroke="medium-gray"
      />
    </div>
    <div>
      <strong
        class="c-product-line__product-name u-flex u-width-100 u-font-size-large"
      >
        {{ product.name }}</strong>
      <span class="u-font-size-large">
        {{ ingredient.formattedAmount }}
      </span>
    </div>
  </div>
</template>
<script>
import missingIcon from '@ds/svg/icons/stroke/allowances-no-photos.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'

export default {
  components: {
    Icon
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    product: {
      type: Object,
      required: true
    },
    ingredient: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      missingIcon
    }
  }
}
</script>
