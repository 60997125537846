<template>
  <div class="c-shopping-cart__vendor-location-container u-flex u-flex-direction-column u-width-80 u-mb--s">
    <div class="c-shopping-cart__vendor-location u-flex-inline u-flex-direction-column">
      <div
        v-if="geoSearchDone"
        class="c-shopping-cart__vendor-location-picked"
      >
        <span class="u-font-size-large u-font-bold">
          {{ postalCode }}, {{ locality }}
        </span>
        <a
          class="u-ml--xs"
          href="#"
          @click.prevent="searchAgain"
        >{{ model.changeText }}</a>
      </div>
      <div
        v-else
        class="c-shopping-cart__vendor-location-search"
      >
        <div class="c-text-input-container u-flex-inline">
          <input
            ref="input"
            v-model.trim="$v.postalCode.$model"
            inputmode="numeric"
            class="c-shoppint-cart__vendor-location-search-input  c-text-input c-text-input--s"
            :class="{
              'c-text-input--error': inputError || focusIndication
            }"
            type="text"
            :maxlength="model.postalCodeMaxLength"
            :placeholder="model.postalCodePlaceholder"
            :disabled="disabled"
            @keypress.enter="onSubmit"
          >
          <Loader
            v-if="isGeoSearchInProgress"
            class="c-shoppint-cart__vendor-location-loader"
            size="medium"
            color="medium-gray"
          />
          <button
            v-if="!isGeoSearchInProgress"
            class="c-shopping-cart__vendor-location-button u-flex u-flex-align-center"
            @click="getUserLocation"
          >
            <Icon
              :icon="iconLocation"
              :size="16"
              stroke="black"
            />
          </button>
        </div>
      </div>
      <FormErrorLine
        v-if="inputError && !noDelivery && !isLoading"
        class="u-mt--xs"
        :text="model.validationErrorText"
      />
    </div>
    <FormErrorLine
      v-if="noDelivery && !isLoading"
      class="u-mt--xs"
      :text="noDeliveryText"
    />
    <p
      v-if="!geoSearchDone"
      class="u-m--0 u-mt--xs u-mb--xs u-font-size-medium"
    >
      {{ preamble }}
    </p>
  </div>
</template>

<script>
import { numeric } from 'vuelidate/lib/validators'
import iconLocation from '@ds/svg/icons/stroke/location-target.svg'
import iconPin from '@ds/svg/icons/bold/pin-bold.svg'
import iconError from '@ds/svg/icons/bold/alert-circle-bold.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import tracker from '@/CVI/WebCore/core-tracker'

import FormErrorLine from '@/CVI/WebCore/components/FormErrorLine.vue'
import Loader from '@/CVI/WebCore/components/Loader.vue'

import store from '@/CVI/Purchase/store'
import api from '@/CVI/Purchase/lib/api'

export default {
  components: {
    Icon,
    FormErrorLine,
    Loader
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    vendor: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  validations: {
    postalCode: {
      numeric
    }
  },
  data() {
    return {
      deliveryCheck: false,
      canDeliverToAddress: false,
      iconLocation,
      iconPin,
      iconError,
      isGeoSearchInProgress: false,
      geoSearchDone: false,
      store,
      postalCodeIsValid: true,
      postalCode: store.state.postalCode,
      focusIndication: false,
      isLoading: false,
      locality: store.state.locality
    }
  },
  computed: {
    inputError() {
      return (this.$v.postalCode.$dirty && this.$v.postalCode.$invalid) || !this.postalCodeIsValid
    },
    preamble() {
      return this.model.preamble.replace('{0}', this.vendor.name)
    },
    noDeliveryText() {
      return this.model.noDeliveryText.replace('{0}', this.vendor.name)
    },
    noDelivery() {
      return !this.canDeliverToAddress && this.deliveryCheck
    }
  },
  watch: {
    async postalCode() {
      this.onSubmit()
    },
    inputError(value) {
      if (value) {
        tracker.track({
          event: 'zipCodeErrorEvent',
          zipCodeError: this.model.validationErrorText,
          zipCodeFiledInput: this.$v.postalCode.$model
        })
      }
    }
  },
  mounted() {
    const { locality, postalCode, selectedVendor } = this.store.state
    if (locality && postalCode && selectedVendor) {
      this.locality = locality
      if (selectedVendor.userPostalCodeRequired) {
        this.geoSearchDone = true
        this.checkIfVendorDelivers(postalCode)
      }
    }
  },
  methods: {
    async onSubmit() {
      const { postalCode } = this
      if (postalCode.toString().length == this.model.postalCodeMaxLength && !this.$v.postalCode.$invalid) {
        this.isLoading = true
        await this.getCoordinates(postalCode)
        await this.checkIfVendorDelivers(postalCode)
        this.isLoading = false
      }
    },
    async getCoordinates(postalCode) {
      this.isGeoSearchInProgress = true
      this.focusIndication = false

      try {
        const { data } = await api.geoCoding.getLocalityInfo(postalCode)
        this.postalCodeIsValid = true
        this.locality = data.locality
      } catch (e) {
        this.postalCodeIsValid = false
      }

      if (this.postalCodeIsValid) {
        this.geoSearchDone = true
      }
      this.isGeoSearchInProgress = false
    },
    getUserLocation() {
      const geoError = () => {
        this.isGeoSearchInProgress = false
      }

      const geoSuccess = async ({
        coords: { latitude, longitude }
      }) => {
        const { postalCode } = (await api.geoCoding.getPostalCode({
          lat: latitude,
          lng: longitude
        })).data
        this.postalCode = postalCode
        await this.getCoordinates(postalCode)
        this.isGeoSearchInProgress = false
      }
      this.isGeoSearchInProgress = true
      navigator.geolocation.getCurrentPosition(geoSuccess, geoError)
    },
    searchAgain() {
      this.geoSearchDone = false
      this.postalCode = ''
      this.deliveryCheck = false
      this.store.dispatch('setCanDeliver', { canDeliver: false })

      this.$nextTick(() => {
        this.focusInput()
      })
    },
    focusInput() {
      this.$refs.input.focus()
    },
    async checkIfVendorDelivers(postalCode) {
      this.isGeoSearchInProgress = true
      const { locality } = this
      const { id: vendorId } = this.store.state.preselectedVendor
      const { data: { canDeliver } } = await api.vendors.canDeliver({ postalCode, vendorId })
      if (canDeliver) {
        this.canDeliverToAddress = true
        this.store.dispatch('setCanDeliver', { canDeliver: true })
        this.store.dispatch('setUserLocality', { locality })
      } else {
        this.canDeliverToAddress = false
        this.store.dispatch('setCanDeliver', { canDeliver: false })
      }
      this.store.state.postalCode = parseInt(postalCode, 10)
      this.deliveryCheck = true
      this.isGeoSearchInProgress = false
    },
    saveVendor() {
      const { vendor } = this
      this.store.state.postalCode = this.postalCode
      this.store.state.selectedVendor = { ...vendor }
    }
  }
}
</script>
