<template>
  <div class="c-shopping-cart__empty-state u-text-align--center">
    <div class="c-shopping-cart__empty-state-box u-mb--m u-mb--m@mobile">
      <span class="c-shopping-cart__disabled u-font-size-xlarge u-font-bold">
        {{ model.callToActionText }}
      </span>
    </div>
    <a
      class="c-button c-button--dark"
      :href="model.callToActionUrl"
      @click.prevent="trackClick()"
    >
      {{ model.callToActionButtonText }}
      <Icon
        class="u-ml--s"
        :icon="iconAdd"
        :size="16"
        stroke="white"
      />
    </a>
  </div>
</template>

<script>
import iconAdd from '@ds/svg/icons/stroke/add.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import tracker from '@/CVI/WebCore/core-tracker'

export default {
  components: {
    Icon
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      iconAdd
    }
  },
  methods: {
    trackClick() {
      tracker.trackSimpleEvent({
        event: 'checkoutFindInspiration'
      })
      setTimeout(() => {
        document.location = this.model.callToActionUrl
      }, 100)
    }
  }
}
</script>
