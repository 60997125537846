<template>
  <div
    class="u-mb--m"
  >
    <h5 class="u-mb--s">
      {{ heading }}
      <span class="u-font-normal u-font-size-large">({{ ingredients.length }})</span>
    </h5>
    <table>
      <template v-for="ingredient in ingredients">
        <component
          :is="tableRow"
          :key="ingredient.ingredientId"
          :ingredient="ingredient"
        />
      </template>
    </table>
  </div>
</template>

<script>
import ShoppingListItem from '@/CVI/Purchase/components/ShoppingListItem.vue'
import ProductLine from '@/CVI/Purchase/components/ProductLine.vue'

export default {
  components: {
    ShoppingListItem,
    ProductLine
  },
  props: {
    ingredients: {
      type: Array,
      required: true
    },
    isDraftFlow: {
      type: Boolean,
      default: false
    },
    heading: {
      type: String,
      default: ''
    }
  },
  computed: {
    tableRow() {
      return this.isDraftFlow ? ProductLine : ShoppingListItem
    }
  }
}
</script>
