<template>
  <tr class="c-product-line">
    <template v-if="product">
      <td colspan="2">
        <div class="c-product-line__row u-flex">
          <ProductInfo
            class="c-product-line__info"
            :class="{
              'c-product-line__disabled': !product.selected || product.amount == 0
            }"
            :product="product"
            :ingredient="ingredient"
            :is-loading="isLoading"
          />
          <div
            class="c-product-line__controls u-flex u-flex-align-center"
          >
            <button
              class="c-button--icon-large c-button--icon-round c-button--icon-hover-dark u-mr--xs"
              :class="{
                'c-product-line__loading': isLoading,
                'c-product-line__disabled': !product.selected || product.amount == 0
              }"
              :style="{
                'visibility': product.alternatives.length ? 'visible' : 'hidden'
              }"
              @click="swapProduct"
            >
              <Icon
                :icon="iconSwap"
                :size="24"
                :stroke="'black'"
                hover-color="white"
              />
            </button>

            <div class="c-product-line__change-amount">
              <ProductAmount
                v-model="product.amount"
                :class="{
                  'c-product-line__loading': isLoading,
                  'c-product-line__disabled': !product.selected || product.amount == 0
                }"
              />
            </div>
            <Checkbox
              v-model="product.selected"
              class="c-product-line__checkbox u-ml--s"
              appearance="checkbox"
              :class="{
                'c-product-line__loading': isLoading
              }"
            />
          </div>
          <transition name="fade">
            <Loader
              v-if="isLoading"
              :key="'loader'"
              class="c-product-line__loader u-ml--a u-mr--a"
              color="medium-gray"
            />
          </transition>
        </div>
      </td>
    </template>
    <template v-else>
      <td colspan="2">
        <div class="c-product-line__row u-flex">
          <div class="c-product-line__info u-flex u-flex-align-center">
            <div class="c-product-line__image-container u-flex-shrink-0 u-mr--s">
              <Icon
                class="c-product-line__missing-icon"
                :icon="missingIcon"
                :size="32"
                stroke="medium-gray"
              />
            </div>
            <div class="u-opacity-40">
              <strong
                class="c-shopping-cart__shopping-list-name u-flex u-width-100 u-font-size-large"
              >
                {{ ingredient.formattedName }}</strong>
              <span class="u-font-size-large">
                {{ ingredient.formattedAmount }}
              </span>
            </div>
          </div>
          <span
            class="u-ml--a u-text-align--center u-text-uppercase u-font-bold u-mr--s u-mt--s@mobile"
          >
            {{ notAvailableText }}</span>
        </div>
      </td>
    </template>
  </tr>
</template>
<script>
import iconSwap from '@ds/svg/icons/stroke/swap.svg'

import missingIcon from '@ds/svg/icons/stroke/allowances-no-photos.svg'

import Icon from '@/CVI/WebCore/components/Icon.vue'
import store from '@/CVI/Purchase/store'
import ProductInfo from '@/CVI/Purchase/components/ProductInfo.vue'
import ProductAmount from '@/CVI/Purchase/components/ProductAmount.vue'
import Loader from '@/CVI/WebCore/components/Loader.vue'
import Checkbox from '@/CVI/WebCore/components/Checkbox.vue'


export default {
  components: {
    Icon,
    Loader,
    ProductInfo,
    Checkbox,
    ProductAmount
  },
  props: {
    ingredient: {
      type: Object,
      required: true
    },
    notAvailableText: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      store,
      missingIcon,
      isLoading: false,
      iconSwap,
      initialAmount: null
    }
  },
  computed: {
    product() {
      return this.store.state.products
        .find(product => product.shoppingListItemId == this.ingredient.id)
    }
  },
  watch: {
    'product.amount': {
      async handler() {
        if (this.isLoading) {
          return
        }
        await this.updateProductAmount()
      }
    },
    'product.selected': {
      async handler() {
        if (this.isLoading) {
          return
        }
        await this.toggleProduct()
      }
    }
  },
  mounted() {
    this.initialAmount = this.product?.amount
  },
  methods: {
    swapProduct() {
      const { product, ingredient } = this
      store.dispatch('showSwapProduct', { product, ingredient })
    },
    async updateProductAmount() {
      const { lineId, amount } = this.product
      this.isLoading = true
      await store.dispatch('updateProduct', {
        lineId,
        amount,
        selected: amount > 0
      })
      this.$nextTick(() => {
        this.isLoading = false
      })
    },
    async toggleProduct() {
      const { lineId, amount, selected } = this.product
      this.isLoading = true

      await store.dispatch('updateProduct', {
        lineId,
        amount: amount == 0 ? 1 : amount,
        selected
      })
      this.$nextTick(() => {
        this.isLoading = false
      })
    }
  }
}
</script>
