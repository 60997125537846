<template>
  <Modal
    v-model="isModalVisible"
    class="c-swap-products-modal"
    :title="model.heading"
    :is-title-centered="false"
  >
    <div class="c-swap-products-modal__container">
      <div
        class="c-swap-products-modal__content"
        :class="{
          'u-opacity-40': isLoading
        }"
      >
        <h5>{{ model.currentProductText }}</h5>
        <table
          class="u-mb--m"
        >
          <tr>
            <td class="u-width-80">
              <ProductInfo
                :product="currentProduct"
                :ingredient="ingredient"
              />
            </td>
            <td class="c-swap-products-modal__current-product u-text-align--right">
              <Checkbox
                v-model="isCurrentProductSelected"
                :alternative="true"
              />
            </td>
          </tr>
        </table>
        <h5>{{ model.relatedProductsText }}</h5>
        <div class="c-swap-products__related">
          <table>
            <tr
              v-for="alternativeProduct in alternatives"
              :key="alternativeProduct.id"
              @click="selectProduct(alternativeProduct)"
            >
              <td class="u-width-80">
                <ProductInfo
                  :product="alternativeProduct"
                  :ingredient="ingredient"
                />
              </td>
              <td class="u-text-align--right">
                <Checkbox
                  v-model="alternativeProduct.isSelected"
                  :alternative="true"
                />
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="u-flex u-flex-justify-center u-mt--s">
        <button
          class="c-button c-button--ghost"
          @click="cancelSwapping"
        >
          {{ model.cancelButtonText }}
        </button>
      </div>
      <Loader
        v-if="isLoading"
        :key="'loader'"
        class="c-swap-products-modal__loader u-ml--a u-mr--a"
        size="large"
        color="medium-gray"
      />
    </div>
  </Modal>
</template>
<script>
import store from '@/CVI/Purchase/store'
import Modal from '@/CVI/WebCore/components/Modal.vue'
import ProductInfo from '@/CVI/Purchase/components/ProductInfo.vue'
import Checkbox from '@/CVI/WebCore/components/Checkbox.vue'
import Loader from '@/CVI/WebCore/components/Loader.vue'
import tracker from '@/CVI/WebCore/core-tracker'


export default {
  components: {
    Modal,
    ProductInfo,
    Checkbox,
    Loader
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      store,
      currentProduct: this.product.product,
      alternatives: this.product.product.alternatives.map(product => ({
        ...product,
        isSelected: false
      })),
      isCurrentProductSelected: true,
      ingredient: this.product.ingredient,
      isModalVisible: false,
      isLoading: false
    }
  },
  watch: {
    isModalVisible(isModalVisible) {
      if (!isModalVisible) {
        this.store.dispatch('hideSwapProduct')
      }
    }
  },
  mounted() {
    this.isModalVisible = true
  },
  methods: {
    async selectProduct(newProduct) {
      this.isLoading = true
      this.isCurrentProductSelected = false
      newProduct.isSelected = true
      const { lineId, amount, selected } = this.product.product
      const { id: newProductId } = newProduct
      await this.store.dispatch('updateProduct', {
        lineId,
        newProductId,
        amount,
        selected
      })
      tracker.track({
        event: 'swapProduct',
        currentProduct: this.product.product.name,
        newProduct: newProduct.name
      })
      this.isModalVisible = false
      this.isLoading = false
    },
    cancelSwapping() {
      this.isModalVisible = false
      tracker.track({
        event: 'swapProductCancel'
      })
    }
  }
}
</script>
