<template>
  <div
    class="c-change-amount u-flex u-ml--a"
    :class="{
      'c-product-line__loading': isLoading
    }"
  >
    <button
      class="c-change-amount__decrease u-flex u-flex-align-center u-flex-justify-center"
      @click="decreaseAmount()"
    >
      <Icon
        :icon="iconSubtract"
        :size="12"
        :stroke="'black'"
      />
    </button>
    <input
      ref="input"
      type="text"
      pattern="[0-9]*"
      inputmode="numeric"
      class="c-change-amount__input"
      :value="value"
      @input="checkMaxAmount($event)"
      @focus="selectElement"
      @keypress="isNumber($event)"
    >
    <button
      class="c-change-amount__increase u-flex u-flex-align-center u-flex-justify-center"
      :disabled="value == maxProductAmount"
      @click="increaseAmount()"
    >
      <Icon
        :icon="iconAdd"
        :size="12"
        :stroke="'black'"
      />
    </button>
  </div>
</template>

<script>
import iconAdd from '@ds/svg/icons/stroke/add.svg'
import iconSubtract from '@ds/svg/icons/stroke/subtract.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import tracker from '@/CVI/WebCore/core-tracker'

export default {
  components: {
    Icon
  },
  props: {
    value: {
      type: Number,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      iconAdd,
      iconSubtract,
      oldValue: '',
      maxProductAmount: 10,
      timer: null
    }
  },
  watch: {
    value: {
      handler(newValue, oldValue) {
        tracker.track({
          event: 'changeAmount',
          amountFrom: oldValue,
          amountTo: newValue
        })
      }
    }
  },
  methods: {
    decreaseAmount() {
      if (this.value > 0) {
        this.$emit('input', parseInt(this.value, 10) - 1)
      } else {
        this.$emit('input', 0)
      }
    },
    increaseAmount() {
      if (this.value < this.maxProductAmount) {
        this.$emit('input', parseInt(this.value, 10) + 1)
      }
    },
    isNumber(event) {
      const evt = (event) || window.event
      const charCode = (evt.which) ? evt.which : evt.keyCode

      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      }

      return true
    },
    selectElement() {
      this.oldValue = this.value
      setTimeout(() => {
        this.$refs.input.select()
        this.$refs.input.setSelectionRange(0, 99999)
      }, 0)
    },
    checkMaxAmount() {
      const { oldValue, maxProductAmount } = this
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        const { value } = this.$refs.input
        if (parseInt(value, 10) > maxProductAmount) {
          this.$refs.input.value = maxProductAmount
          this.$emit('input', maxProductAmount)
        } else if (!value) {
          this.$emit('input', oldValue)
        } else {
          this.$emit('input', parseInt(value, 10))
        }
      }, 100)
    }
  }
}
</script>
