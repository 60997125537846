<template>
  <div>
    <BasicIngredients
      v-if="store.state.basicIngredients"
      :ingredients="store.state.basicIngredients"
      :is-draft-flow="store.getters.isDraftFlow"
      :heading="model.basicIngredientsGroupText"
    />
    <div
      v-for="group in shoppingList"
      :key="group.id"
      class="u-mb--m"
    >
      <h5 class="u-mb--s">
        {{ group.name }}
        <span class="u-font-normal u-font-size-large">({{ group.items.length }})</span>
      </h5>
      <table>
        <template v-for="ingredient in group.items">
          <component
            :is="tableRow"
            :key="ingredient.ingredientId"
            :ingredient="ingredient"
            :not-available-text="model.productNotAvailableText"
          />
        </template>
      </table>
    </div>
  </div>
</template>

<script>
import store from '@/CVI/Purchase/store'
import ShoppingListItem from '@/CVI/Purchase/components/ShoppingListItem.vue'
import ProductLine from '@/CVI/Purchase/components/ProductLine.vue'
import BasicIngredients from '@/CVI/Purchase/components/ShoppingListBasicIngredients.vue'

export default {
  components: {
    ShoppingListItem,
    ProductLine,
    BasicIngredients
  },
  props: {
    shoppingList: {
      type: Array,
      required: true
    },
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      store
    }
  },
  computed: {
    tableRow() {
      return this.store.getters.isDraftFlow ? ProductLine : ShoppingListItem
    }
  }
}
</script>
