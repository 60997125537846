<template>
  <td
    @click="resetVendor()"
  >
    <div class="c-shopping-cart__vendors--offline u-flex">
      <Checkbox
        role="button"
        tabindex="0"
        v-model="isSelected"
        :alternative="true"
        class="u-hidden-print u-mr--xs"
      />
      <span
        class="u-flex u-flex-align-center u-font-bold"
      >
        <Icon
          class="u-mr--xs"
          :icon="iconShoppingCart"
          :size="16"
          stroke="black"
        />
        {{ model.buyOfflineText }}
      </span>
    </div>
  </td>
</template>

<script>
import iconShoppingCart from '@ds/svg/icons/stroke/shopping-cart.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import Checkbox from '@/CVI/WebCore/components/Checkbox.vue'
import store from '@/CVI/Purchase/store'

export default {
  components: {
    Checkbox,
    Icon
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      store,
      iconShoppingCart
    }
  },
  computed: {
    isSelected: {
      get() {
        return this.store.state.preselectedVendor == 'none'
      },
      set() {
        this.resetVendor()
      }
    }
  },
  methods: {
    resetVendor() {
      this.store.state.preselectedVendor = 'none'
      this.store.dispatch('clearVendor')
      this.store.dispatch('clearOneTimeVendor')
    }
  }
}
</script>
