var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"c-product-line"},[(_vm.product)?[_c('td',{attrs:{"colspan":"2"}},[_c('div',{staticClass:"c-product-line__row u-flex"},[_c('ProductInfo',{staticClass:"c-product-line__info",class:{
            'c-product-line__disabled': !_vm.product.selected || _vm.product.amount == 0
          },attrs:{"product":_vm.product,"ingredient":_vm.ingredient,"is-loading":_vm.isLoading}}),_c('div',{staticClass:"c-product-line__controls u-flex u-flex-align-center"},[_c('button',{staticClass:"c-button--icon-large c-button--icon-round c-button--icon-hover-dark u-mr--xs",class:{
              'c-product-line__loading': _vm.isLoading,
              'c-product-line__disabled': !_vm.product.selected || _vm.product.amount == 0
            },style:({
              'visibility': _vm.product.alternatives.length ? 'visible' : 'hidden'
            }),on:{"click":_vm.swapProduct}},[_c('Icon',{attrs:{"icon":_vm.iconSwap,"size":24,"stroke":'black',"hover-color":"white"}})],1),_c('div',{staticClass:"c-product-line__change-amount"},[_c('ProductAmount',{class:{
                'c-product-line__loading': _vm.isLoading,
                'c-product-line__disabled': !_vm.product.selected || _vm.product.amount == 0
              },model:{value:(_vm.product.amount),callback:function ($$v) {_vm.$set(_vm.product, "amount", $$v)},expression:"product.amount"}})],1),_c('Checkbox',{staticClass:"c-product-line__checkbox u-ml--s",class:{
              'c-product-line__loading': _vm.isLoading
            },attrs:{"appearance":"checkbox"},model:{value:(_vm.product.selected),callback:function ($$v) {_vm.$set(_vm.product, "selected", $$v)},expression:"product.selected"}})],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.isLoading)?_c('Loader',{key:'loader',staticClass:"c-product-line__loader u-ml--a u-mr--a",attrs:{"color":"medium-gray"}}):_vm._e()],1)],1)])]:[_c('td',{attrs:{"colspan":"2"}},[_c('div',{staticClass:"c-product-line__row u-flex"},[_c('div',{staticClass:"c-product-line__info u-flex u-flex-align-center"},[_c('div',{staticClass:"c-product-line__image-container u-flex-shrink-0 u-mr--s"},[_c('Icon',{staticClass:"c-product-line__missing-icon",attrs:{"icon":_vm.missingIcon,"size":32,"stroke":"medium-gray"}})],1),_c('div',{staticClass:"u-opacity-40"},[_c('strong',{staticClass:"c-shopping-cart__shopping-list-name u-flex u-width-100 u-font-size-large"},[_vm._v(" "+_vm._s(_vm.ingredient.formattedName))]),_c('span',{staticClass:"u-font-size-large"},[_vm._v(" "+_vm._s(_vm.ingredient.formattedAmount)+" ")])])]),_c('span',{staticClass:"u-ml--a u-text-align--center u-text-uppercase u-font-bold u-mr--s u-mt--s@mobile"},[_vm._v(" "+_vm._s(_vm.notAvailableText))])])])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }