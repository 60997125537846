<template>
  <div>
    <template v-if="model.isScalable">
      <Popover
        v-model="isDropdownShown"
        :placement="'bottom'"
        :auto-close="true"
        :auto-close-delay="100000"
      >
        <div
          class="c-portion-selector__input-text u-flex"
          @click="isDropdownShown = true"
        >
          <span class="u-text-nowrap">{{ selectedPortionText }}</span>
          <Icon
            class="u-ml--xs u-hidden-print"
            :icon="iconArrowDown"
            :size="12"
            :fill="iconColor"
          />
        </div>
        <select
          class="c-portion-selector__dropdown"
          @change="updatePortionValueFromSelect"
        >
          <option
            v-for="option in model.options"
            :key="option.value"
            :value="option.value"
            :selected="option.text == selectedPortionText"
          >
            {{ option.text }}
          </option>
        </select>
        <template slot="popover">
          <ul class="c-portion-selector__options u-font-size-large">
            <li
              v-for="option in model.options"
              :key="option.value"
              class="c-portion-selector__option"
            >
              <a
                href="#"
                :class="{
                  'is-selected': option.text == selectedPortionText
                }"
                @click.prevent="updatePortionValue(option)"
              >
                {{ option.text }}</a>
            </li>
          </ul>
        </template>
      </Popover>
    </template>
    <template v-else>
      <span>{{ model.notScalablePortionText }}</span>
    </template>
  </div>
</template>

<script>
import iconArrowDown from '@ds/svg/icons/bold/arrow-down-1-bold.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import Popover from '@/CVI/WebCore/components/Popover.vue'

export default {
  components: {
    Popover,
    Icon
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    iconColor: {
      type: String,
      default: 'white'
    },
    portionCount: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isDropdownShown: false,
      iconArrowDown
    }
  },
  computed: {
    selectedPortionText() {
      const { options } = this.model
      return options.find(({ value }) => value == this.portionCount).text
    }
  },
  methods: {
    updatePortionValue({ value }) {
      this.isDropdownShown = false
      this.$emit('portion-change', value)
    },
    updatePortionValueFromSelect(event) {
      this.$emit('portion-change', event.target.value)
    }
  }
}
</script>
