<template>
  <div
    ref="shoppingCart"
    class="c-shopping-cart"
    :style="{'padding-top': (isBuyButtonSticky && !store.state.isModalVendorSelectorVisible && $mq == 'desktop') ? `${this.$refs.topBar.clientHeight}px` : null}"
  >
    <div
      ref="topBar"
      class="c-shopping-card__top-bar u-flex u-flex-align-center u-flex-justify-sides u-hidden-print"
      :class="{
        'is-sticky': isBuyButtonSticky && !store.state.isModalVendorSelectorVisible,
      }"
    >
      <h2
        ref="shoppingButtonStickyTarget"
        class="u-m--0 u-font-size-h4"
      >
        {{ model.shoppingList.shoppingListHeading }}
      </h2>
      <div
        class="u-flex u-flex-align-center"
      >
        <VendorOverview
          v-show="store.state.shoppingList.length"
          :model="model.vendorOverview"
          class="u-hidden-print"
        />
        <VendorCheckout
          v-if="store.state.shoppingList.length && store.state.vendors.length"
          :style="{
            'opacity': (store.state.selectedVendor && store.state.selectedVendor != 'none' && recipeCount > 0) ? 1 : 0
          }"
          :class="{
            'u-hidden-visually': (!store.state.selectedVendor || store.state.selectedVendor == 'none' || recipeCount == 0)
          }"
          :checkout-text="model.checkoutText"
          :change-vendor-text="model.changeVendorText"
          :matched-count-loading="isLoading"
          :total-count="ingredientCount"
          class="u-ml--a u-hidden-print"
        />
      </div>
      <button
        v-if="$mq == 'desktop' && store.state.shoppingList.length"
        ref="printButton"
        class="c-button c-button--ghost"
        @click.prevent="print()"
      >
        <Icon
          :icon="iconPrint"
          :size="16"
          stroke="black"
          class="u-mr--xs"
        />
        <span>{{ model.printButtonText }}</span>
      </button>

      <div
        v-if="$mq != 'desktop'"
        class="c-shopping-cart__buttons-inner u-flex"
        :class="{
          'is-active': store.state.isModalClearCartVisible
        }"
      >
        <ClearShoppingCart
          :model="model.clearShoppingCart"
          @clear="onShoppingCartClear"
        />
      </div>
    </div>

    <div
      class="c-shopping-cart__content u-flex u-flex-align-center u-flex-direction-column u-width-720 u-ml--a u-mr--a"
      :class="{
        'o-content-box--side': $mq != 'desktop'
      }"
    >
      <div
        v-if="$mq == 'desktop' && (!store.state.selectedVendor || store.state.selectedVendor == 'none') && store.state.shoppingList.length"
        class="u-flex u-flex-align-center u-mt--m u-mb--m u-hidden-print"
      >
        <Picture
          v-for="vendor in store.state.vendors"
          :key="vendor.title"
          :src="vendor.logo"
          class="c-shopping-cart__vendor-logo u-ml--xxs u-mr--xxs"
          :resizer="vendor.imageResizer.name"
          :alt="vendor.title"
          :mobile="[70, 30]"
          :options="{ mode : 'max'}"
        />
      </div>
      <div
        ref="shoppingButtons"
        class="c-shopping-cart__buttons u-flex u-width-100 u-flex-justify-center u-mb--m u-mb--s@mobile u-hidden-print"
        :class="{
          'c-shopping-cart__disabled': recipeCount == 0,
          'is-opened': store.state.isModalClearCartVisible,
          'u-mt--m': store.state.selectedVendor && store.state.selectedVendor != 'none' || !store.state.shoppingList.length
        }"
      >
        <div
          class="c-radio-group u-flex"
        >
          <button
            class="c-radio-group__button"
            :class="{
              'is-active': selectedTab == 'shopping-list',
              'c-radio-group__button--alternative': $mq != 'desktop'
            }"
            @click="selectedTab = 'shopping-list'"
          >
            {{ model.shoppingList.shoppingListTabText }} ({{ ingredientCount }})
          </button>
          <button
            class="c-radio-group__button"
            :class="{
              'is-active': selectedTab == 'recipe-list',
              'c-radio-group__button--alternative': $mq != 'desktop'
            }"
            @click="selectedTab = 'recipe-list'"
          >
            {{ model.shoppingList.recipeListTabText }}
            <span class="u-ml--xs u-font-normal">({{ recipeCount }})</span>
          </button>
        </div>
      </div>

      <div class="c-shopping-cart__list">
        <transition-group
          name="fade-purchase"
          mode="out-in"
        >
          <Loader
            v-if="isLoading"
            :key="'loader'"
            class="c-shopping-cart__loader u-ml--a u-mr--a"
            size="large"
            color="medium-gray"
          />
          <ShoppingList
            v-if="store.state.shoppingList.length && selectedTab == 'shopping-list' && !isLoading"
            :key="'shopping-list'"
            :shopping-list="store.state.shoppingList"
            :model="model.shoppingList"
          />
          <RecipeList
            v-if="store.state.recipeList.length && selectedTab == 'recipe-list' && !isLoading"
            :key="'recipe-list'"
            :recipe-list="store.state.recipeList"
            :search-recipes-button-text="model.searchRecipesButtonText"
            :weekdays="model.weekDays"
            :basic-ingredients-heading="model.shoppingList.basicIngredientsGroupText"
          />
          <EmptyState
            v-if="recipeCount == 0"
            :key="'empty-state'"
            :model="model.emptyState"
          />
        </transition-group>
      </div>
      <SwapProduct
        v-if="store.state.swapProduct"
        :product="store.state.swapProduct"
        :model="model.swapProduct"
      />
    </div>
  </div>
</template>

<script>
import iconPrint from '@ds/svg/icons/stroke/print-text.svg'
import Loader from '@/CVI/WebCore/components/Loader.vue'
import ShoppingList from '@/CVI/Purchase/components/ShoppingList.vue'
import VendorOverview from '@/CVI/Purchase/components/VendorOverview.vue'
import VendorCheckout from '@/CVI/Purchase/components/VendorCheckout.vue'
import RecipeList from '@/CVI/Purchase/components/RecipeList.vue'
import store from '@/CVI/Purchase/store'
import SwapProduct from '@/CVI/Purchase/components/SwapProduct.vue'
import Picture from '@/CVI/WebCore/components/Picture.vue'

import Icon from '@/CVI/WebCore/components/Icon.vue'
import ClearShoppingCart from '@/CVI/Purchase/components/ClearShoppingCart.vue'

import EmptyState from '@/CVI/Purchase/components/EmptyState.vue'
import tracker from '@/CVI/WebCore/core-tracker'

export default {
  components: {
    Icon,
    EmptyState,
    VendorOverview,
    ShoppingList,
    RecipeList,
    VendorCheckout,
    Loader,
    ClearShoppingCart,
    SwapProduct,
    Picture
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      store,
      iconPrint,
      header: document.querySelector('.c-header'),
      selectedTab: 'shopping-list',
      isBuyButtonSticky: false
    }
  },
  computed: {
    recipePortions() {
      return this.store.state.cart.map(({ uid, portionCount }) => {
        return {
          recipeId: uid,
          portions: portionCount
        }
      })
    },
    recipeCount() {
      return this.store.state.cart.length
    },
    ingredientCount() {
      return this.store.state.shoppingList.reduce((total, group) => {
        return total + group.items.length
      }, 0)
    },
    isLoading() {
      return this.store.state.isShoppingListLoading
        || this.store.state.isRecipeListLoading
        || this.store.state.isProductsLoading
    },
    stickyPoint() {
      return this.$refs.shoppingCart.offsetTop
    }
  },
  watch: {
    'store.state.cart': {
      handler() {
        // if card changed and new flow fetch only product list and reset saved basket id
        if (this.store.state.selectedVendor
          && this.store.state.selectedVendor !== 'none'
          && this.store.state.selectedVendor.shoppingCartDraftEnabled
          && this.store.state.cart.length) {
          this.store.state.selectedVendor.shoppingCartId = null
          this.fetchProductData(this.store.state.selectedVendor)
        } else {
          // old flow
          this.fetchCartData()
        }
      }
    },
    selectedTab: {
      handler(value) {
        if (this.$mq !== 'desktop') {
          this.scrollToCartContent()
        }
        tracker.track({
          event: value == 'shopping-list'
            ? 'ingredientsTabClick' : 'recipesTabClick'
        })
      }
    },
    'store.state.selectedVendor': {
      handler(vendor) {
        const { postalCode, cart } = this.store.state
        if (vendor && cart.length) {
          const { shoppingCartDraftEnabled, userPostalCodeRequired } = vendor
          const vendorNoPostalCodeRequired = shoppingCartDraftEnabled && !userPostalCodeRequired
          const vendorPostalCodeSaved = shoppingCartDraftEnabled && userPostalCodeRequired && postalCode
          if (vendorNoPostalCodeRequired || vendorPostalCodeSaved) {
            this.fetchProductData(vendor)
          } else {
            this.fetchCartData()
          }
        }
      }
    }
  },
  async mounted() {
    const { savedVendor, quickVendorButtonClicked } = this.store.getters
    if (quickVendorButtonClicked) {
      this.store.dispatch('removeQuickVendorButtonClick')
      this.selectedTab = 'recipe-list'
    }
    if (savedVendor && savedVendor != 'none') {
      await store.dispatch('fetchVendors', {})
      const vendor = this.store.state.vendors.find(({ id }) => id == savedVendor)
      const { postalCode } = this.store.state
      if (!vendor.userPostalCodeRequired || (vendor.userPostalCodeRequired && postalCode)) {
        this.store.state.selectedVendor = { ...vendor }
      } else {
        this.store.state.preselectedVendor = { ...vendor }
        if (window.OneTrust && window.OneTrust.IsAlertBoxClosed()) {
          this.store.state.isModalVendorSelectorVisible = true
        }
        if (!window.OneTrust) {
          this.store.state.isModalVendorSelectorVisible = true
        }
        this.fetchCartData()
      }
    } else {
      if (window.OneTrust && window.OneTrust.IsAlertBoxClosed()) {
        this.store.state.isModalVendorSelectorVisible = true
      }
      if (!window.OneTrust) {
        this.store.state.isModalVendorSelectorVisible = true
      }
      this.fetchCartData()
      store.dispatch('fetchVendors', {})
    }

    window.addEventListener('scroll', this.showStickyBar)
  },
  methods: {
    fetchCartData() {
      const {
        recipePortions
      } = this
      store.dispatch('fetchShoppingList', { recipePortions })
    },
    fetchProductData(vendor) {
      const { recipePortions } = this
      const {
        postalCode
      } = store.state

      this.store.dispatch('fetchProductList', {
        recipePortions,
        vendorName: vendor.id,
        postalCode,
        shoppingCartId: vendor.shoppingCartId,
        draft: vendor.shoppingCartDraftEnabled
      })
    },
    scrollToCartContent() {
      const offset = this.$refs.shoppingButtonStickyTarget.offsetTop
      if (offset < window.scrollY) {
        window.scrollTo(0, offset - this.header.offsetHeight - this.header.offsetTop)
      }
    },
    print() {
      this.$refs.printButton.blur()
      tracker.track({
        event: 'checkoutPrint',
        printType: this.selectedTab == 'shopping-list'
          ? 'ingredients' : 'recipes'
      })
      setTimeout(() => {
        window.print()
      }, 200)
    },
    onShoppingCartClear() {
      if (this.$mq !== 'desktop') {
        this.scrollToCartContent()
      }
    },
    showStickyBar() {
      this.isBuyButtonSticky = window.scrollY > this.stickyPoint
    }
  }
}
</script>
