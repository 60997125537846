<template>
  <div ref="modal">
    <p class="u-m--0 u-mb--m u-font-size-large">
      {{ model.vendorSelector.legalText }}
    </p>
    <transition
      name="fade-purchase"
      mode="out-in"
    >
      <template v-if="store.state.vendors.length">
        <table
          ref="table"
          class="c-shopping-cart__vendor-table"
        >
          <tr
            v-for="vendor in store.state.vendors"
            :key="vendor.name"
          >
            <td>
              <div
                class="u-flex u-width-100"
                @click.prevent="selectVendor(vendor)"
              >
                <VendorSelectorItem
                  :vendor="vendor"
                  :not-available-text="model.vendorSelector.vendorNotAvailableText"
                />
              </div>
              <UserLocation
                v-if="isUserLocationShown(vendor)"
                class="u-mt--s"
                :model="model.userLocation"
                :vendor="vendor"
                :disabled="store.state.preselectedVendor && store.state.preselectedVendor.id != vendor.id"
              />
            </td>
          </tr>
          <tr>
            <VendorOfflineSelector
              class="u-text-align--left"
              :model="model.vendorSelector"
            />
          </tr>
        </table>
      </template>
      <template v-else>
        <Loader
          class="u-ml--a u-flex u-mr--a"
          size="large"
          color="medium-gray"
        />
      </template>
    </transition>
  </div>
</template>

<script>
import iconArrow from '@ds/svg/icons/bold/arrow-right-1-bold.svg'


import Loader from '@/CVI/WebCore/components/Loader.vue'
import VendorSelectorItem from '@/CVI/Purchase/components/VendorSelectorItem.vue'
import VendorOfflineSelector from '@/CVI/Purchase/components/VendorOfflineSelector.vue'
import UserLocation from '@/CVI/Purchase/components/UserLocation.vue'

import store from '@/CVI/Purchase/store'
import tracker from '@/CVI/WebCore/core-tracker'
import api from '@/CVI/Purchase/lib/api'


export default {
  components: {
    VendorSelectorItem,
    VendorOfflineSelector,
    Loader,
    UserLocation
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    postalCodeIsValid: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      store,
      iconArrow,
      checkingVendor: false
    }
  },
  computed: {

  },
  watch: {
    'store.state.selectedVendor': {
      handler(vendor) {
        let vendorRank
        let vendorName

        if (vendor && vendor != 'none') {
          vendorRank = this.store.state.vendors.indexOf(vendor) + 1
          vendorName = vendor.id
        } else {
          vendorRank = this.store.state.vendors.length + 1
          vendorName = 'offline'
        }
        tracker.trackSelectVendor({
          vendor: vendorName,
          vendorRank,
          products: this.store.getters.trackingRecipes
        })
      }
    }
  },
  methods: {
    isUserLocationShown({ userPostalCodeRequired, id }) {
      const { preselectedVendor, locality, postalCode } = this.store.state

      if (userPostalCodeRequired && locality && postalCode) {
        return true
      }
      return userPostalCodeRequired && preselectedVendor && id == preselectedVendor.id
    },
    async selectVendor(vendor) {
      this.store.state.preselectedVendor = vendor
      if (!vendor.userPostalCodeRequired) {
        this.store.dispatch('setCanDeliver', { canDeliver: false })
      } else {
        await this.checkIfVendorDelivers(vendor)
      }
    },
    async checkIfVendorDelivers(vendor) {
      this.checkingVendor = true
      const { postalCode } = this.store.state
      const {
        data: { canDeliver }
      } = await api.vendors.canDeliver({ postalCode, vendorId: vendor.id })
      this.store.dispatch('setCanDeliver', { canDeliver })
      this.checkingVendor = false
    }
  }
}
</script>
