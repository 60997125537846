<template>
  <div>
    <Modal
      v-model="store.state.isModalVendorSelectorVisible"
      class="c-shopping-cart__vendors-modal"
      :title="model.heading"
      :is-title-centered="false"
    >
      <VendorSelector
        :model="model"
      />
      <div class="u-flex u-flex-direction-column@mobile u-mt--m u-flex-justify-end u-ml--a u-mr--a">
        <button
          class="c-button c-button--dark"
          :disabled="isButtonsDisabled"
          :class="{
            'u-ml--s': $mq == 'desktop'
          }"
          @click="saveVendor"
        >
          {{ model.vendorSelector.savePreferencesText }}
        </button>
        <button
          class="c-button c-button--light-gray"
          :disabled="isButtonsDisabled"
          :class="{
            'u-mt--s': $mq != 'desktop'
          }"
          :style="{
            order: $mq == 'desktop' ? -1 : 0
          }"
          @click="saveLater"
        >
          <template v-if="store.state.preselectedVendor">
            {{ model.vendorSelector.saveOneTimeText }}
          </template>
          <template v-else>
            {{ model.vendorSelector.saveLaterText }}
          </template>
        </button>
      </div>
    </Modal>
    <div
      v-if="!store.state.selectedVendor || store.state.selectedVendor == 'none'"
      class="c-shopping-cart__vendors-toolbar u-flex u-flex-align-center"
      :class="{
        'is-disabled': !store.state.cart.length
      }"
    >
      <span
        v-if="$mq != 'desktop'"
        class="u-font-size-large u-mr--xs"
      >
        {{ model.vendorSelector.wantToOrderText }}
      </span>
      <button
        v-if="$mq != 'desktop'"
        class="c-button c-button--light-gray u-flex-shrink-0 u-ml--a"
        @click="showModal"
      >
        {{ model.vendorSelector.buttonText }}
      </button>
      <button
        v-if="$mq == 'desktop'"
        class="c-button c-button--ghost u-flex-shrink-0 u-ml--a"
        @click="showModal"
      >
        <span class="u-font-normal u-text-transform-none u-font-size-large u-mr--xs">
          {{ model.vendorSelector.wantToOrderText }}
        </span>
        {{ model.vendorSelector.buttonText }}
        <Icon
          class="u-flex-shrink-0 u-ml--xxs"
          :icon="iconArrow"
          :size="16"
          fill="dark"
        />
      </button>
    </div>
  </div>
</template>

<script>
import iconArrow from '@ds/svg/icons/bold/arrow-right-1-bold.svg'
import Modal from '@/CVI/WebCore/components/Modal.vue'
import VendorSelector from '@/CVI/Purchase/components/VendorSelector.vue'
import store from '@/CVI/Purchase/store'
import Icon from '@/CVI/WebCore/components/Icon.vue'

export default {
  components: {
    Modal,
    VendorSelector,
    Icon
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      store,
      iconArrow
    }
  },
  computed: {
    isButtonsDisabled() {
      if (store.state.preselectedVendor && !store.state.preselectedVendor.userPostalCodeRequired) {
        return false
      }

      return !store.state.canDeliver
    }
  },
  methods: {
    showModal() {
      this.store.state.isModalVendorSelectorVisible = true
    },
    saveVendor() {
      const { id } = this.store.state.preselectedVendor
      this.store.dispatch('saveVendor', { id: id || 'none', vendor: this.store.state.preselectedVendor })
      this.closeModal()
    },
    saveLater() {
      if (this.store.state.preselectedVendor) {
        const { id } = this.store.state.preselectedVendor
        this.store.dispatch('saveOneTimeVendor', { id: id || 'none', vendor: this.store.state.preselectedVendor })
      }
      this.store.dispatch('clearVendor')
      this.closeModal()
    },
    closeModal() {
      this.store.state.isModalVendorSelectorVisible = false
    }
  }
}
</script>
