<template>
  <tr>
    <td
      v-if="ingredient.productUrl"
      class="u-width-70"
    >
      <a
        class="u-flex u-flex-align-center"
        :href="ingredient.productUrl"
      >
        <Picture
          class="u-mr--s"
          :src="ingredient.productImageUrl"
          :resizer="ingredient.imageResizer.name"
          :alt="ingredient.formattedName"
          :mobile="[48, 48]"
        />
        <div class="u-font-size-large">
          <strong class="c-shopping-cart__shopping-list-name">
            {{ ingredient.formattedName }}</strong>
        </div>
      </a>
    </td>
    <td
      v-else
      class="u-width-70"
    >
      <div class="u-font-size-large">
        <strong class="c-shopping-cart__shopping-list-name">
          {{ ingredient.formattedName }}</strong>
      </div>
    </td>
    <td class="u-text-align--right">
      <span class="u-font-size-large">
        {{ ingredient.formattedAmount }}
      </span>
    </td>
  </tr>
</template>

<script>
import Picture from '@/CVI/WebCore/components/Picture.vue'

export default {
  components: {
    Picture
  },
  props: {
    ingredient: {
      type: Object,
      required: true
    }
  }
}
</script>
