<template>
  <div>
    <button
      ref="clearButton"
      class="c-button--icon-large c-button--icon-round c-button--icon-border c-button--icon-white"
      @click.prevent="isModalVisible = true"
    >
      <Icon
        :icon="iconTrash"
        :size="24"
        fill="black"
      />
    </button>
    <Modal
      v-model="isModalVisible"
      :title="modalTitle"
      :is-title-centered="false"
    >
      <div class="u-flex u-flex-justify-end u-mt--m">
        <button
          class="c-button c-button--light u-mr--s"
          @click="cancel()"
        >
          {{ model.cancelButtonText }}
        </button>
        <button
          class="c-button c-button--dark"
          @click="clearCart()"
        >
          <Icon
            class="u-mr--xs"
            :icon="iconTrash"
            :size="16"
            fill="white"
          />
          {{ model.buttonText }}
        </button>
      </div>
    </Modal>
  </div>
</template>
<script>
import iconTrash from '@ds/svg/icons/bold/bin-1-bold.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import Modal from '@/CVI/WebCore/components/Modal.vue'
import store from '@/CVI/Purchase/store'
import tracker from '@/CVI/WebCore/core-tracker'

export default {
  components: {
    Icon,
    Modal
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      iconTrash,
      store,
      cancelClicked: false,
      isModalVisible: false
    }
  },
  computed: {
    modalTitle() {
      return this.model.textFormat ? this.model.textFormat.replace('{0}', this.store.state.cart.length) : ''
    }
  },
  watch: {
    isModalVisible(value) {
      this.store.state.isModalClearCartVisible = value
      if (value) {
        tracker.track({
          event: 'checkoutDeleteList',
          checkoutDeleteListStep: 'step 1'
        })
      }
      if (!value && !this.cancelClicked) {
        tracker.track({
          event: 'checkoutDeleteListCancel',
          checkoutDeleteCancelType: 'cross'
        })
      }
    }
  },
  methods: {
    clearCart() {
      this.$refs.clearButton.blur()
      this.store.dispatch('clearCart')
      this.cancelClicked = true
      setTimeout(() => {
        this.cancelClicked = false
      }, 100)
      this.isModalVisible = false
      this.$emit('clear')
      tracker.track({
        event: 'checkoutDeleteList',
        checkoutDeleteListStep: 'step 2'
      })
    },
    cancel() {
      this.cancelClicked = true
      setTimeout(() => {
        this.cancelClicked = false
      }, 100)
      this.isModalVisible = false
      tracker.track({
        event: 'checkoutDeleteListCancel',
        checkoutDeleteCancelType: 'cancel'
      })
    }
  }
}
</script>
