<template>
  <div
    ref="elem"
    class="c-shopping-cart__vendor u-flex u-flex-align-center u-width-100"
    :class="{
      'c-shopping-cart__vendors-disabled': disabled
    }"
  >
    <div class="u-flex u-flex-align-center u-width-80">
      <Checkbox
        v-model="isSelected"
        :disabled="disabled"
        :alternative="true"
        class="u-hidden-print u-mr--xs"
      />
      <Picture
        class="c-shopping-cart__vendor-image u-flex-shrink-0"
        :class="pictureClass"
        :src="vendor.logo"
        :resizer="vendor.imageResizer.name"
        :alt="vendor.title"
        :mobile="[100, 50]"
        :options="{ mode : 'max'}"
      />
    </div>
    <strong
      v-if="vendor.deliveryEnabled"
      :class="{
        'u-opacity-40': disabled
      }"
      class="u-font-size-medium"
    >{{ delivery }}</strong>
    <strong
      v-else
      :class="{
        'u-opacity-40': disabled
      }"
      class="u-font-size-medium u-font-italic"
    >{{ notAvailableText }}</strong>
  </div>
</template>

<script>
import Picture from '@/CVI/WebCore/components/Picture.vue'
import Checkbox from '@/CVI/WebCore/components/Checkbox.vue'
import store from '@/CVI/Purchase/store'

export default {
  components: {
    Picture,
    Checkbox
  },
  props: {
    vendor: {
      type: Object,
      required: true
    },
    notAvailableText: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      store,
      width: 0
    }
  },
  computed: {
    delivery() {
      return this.vendor.deliveryOptions[0]
    },
    isSelected: {
      get() {
        return this.store.state.preselectedVendor?.name == this.vendor.name
      },
      set(newValue) {
        if (newValue) {
          this.store.state.preselectedVendor = this.vendor
        }
      }
    },
    pictureClass() {
      return this.width < 190 ? 'u-mr--xs' : 'u-mr--m'
    }
  },
  mounted() {
    this.width = this.$refs.elem.offsetWidth
    window.addEventListener('resize', () => {
      this.width = this.$refs.elem?.offsetWidth
    })
  }
}
</script>
