<template>
  <table>
    <tr class="c-shopping-cart__recipe-heading">
      <td class="u-width-80">
        <div class="u-flex u-flex-align-center u-flex-1">
          <Picture
            class="c-shopping-cart__recipe-image u-mr--s u-flex-shrink-0 u-hidden-print"
            :src="recipe.picture"
            :resizer="recipe.imageResizer.name"
            :alt="recipe.name"
            :mobile="[48, 48]"
          />
          <div class="u-flex u-flex-direction-column  u-flex-1">
            <span class="c-label--s u-text-medium-gray u-hidden@mobile">
              {{ recipe.preparationTime }}</span>
            <strong class="u-font-size-xlarge">
              {{ recipe.name }}</strong>
            <PortionSelector
              v-if="$mq !== 'desktop'"
              class="c-shopping-cart__portion-selector u-mt--xs"
              :model="recipe.portionSelector"
              :portion-count="portionCount"
              @portion-change="onPortionChange"
            />
          </div>
        </div>
      </td>
      <td>
        <div class="u-flex u-flex-align-center u-flex-justify-end">
          <PortionSelector
            v-if="$mq == 'desktop'"
            class="c-shopping-cart__portion-selector u-mr--s"
            :model="recipe.portionSelector"
            :portion-count="portionCount"
            @portion-change="onPortionChange"
          />
          <button
            class="c-button--icon-large u-hidden-print"
            @click="removeRecipe(recipe.id)"
          >
            <Icon
              :icon="iconTrash"
              :size="24"
              fill="white"
            />
          </button>
        </div>
      </td>
    </tr>

    <template v-for="ingredient in recipe.ingredients">
      <component
        :is="recipeLine"
        :key="ingredient.id"
        :ingredient="ingredient"
      />
    </template>
    <tr
      v-if="recipe.basicIngredients.length"
      class="c-shopping-cart__recipe-basic-heading"
    >
      <td
        class="u-font-size-medium"
        colspan="2"
      >
        {{ basicIngredientsHeading }}
      </td>
    </tr>
    <template v-for="ingredient in recipe.basicIngredients">
      <component
        :is="recipeLine"
        :key="ingredient.id"
        :ingredient="ingredient"
      />
    </template>
  </table>
</template>

<script>
import iconTrash from '@ds/svg/icons/bold/bin-1-bold.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import Picture from '@/CVI/WebCore/components/Picture.vue'
import PortionSelector from '@/CVI/Purchase/components/PortionSelector.vue'
import tracker from '@/CVI/WebCore/core-tracker'
import store from '@/CVI/Purchase/store'

import ShoppingListItem from '@/CVI/Purchase/components/ShoppingListItem.vue'
import ProductLine from '@/CVI/Purchase/components/ProductLine.vue'

export default {
  components: {
    Icon,
    Picture,
    PortionSelector,
    ShoppingListItem,
    ProductLine
  },
  props: {
    recipe: {
      type: Object,
      required: true
    },
    basicIngredientsHeading: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      iconTrash,
      store
    }
  },
  computed: {
    portionCount() {
      // TODO update when switch to UID
      // eslint-disable-next-line
      const portionCount = this.store.state.cart.find(({ uid }) => (this.recipe.id == uid || `rdb:${this.recipe.id}` == uid)).portionCount
      // // need to have initial portion set in order to make cache working in purchase store fetch recipe list
      // eslint-disable-next-line
      this.recipe.initialPortion = portionCount
      return portionCount
    },
    recipeLine() {
      return this.store.getters.isDraftFlow
        ? ProductLine : ShoppingListItem
    }
  },
  methods: {
    onPortionChange(portionCount) {
      const { id: uid } = this.recipe
      tracker.track({
        event: 'checkoutPortionsClick',
        checkoutPortionChange: portionCount
      })
      store.dispatch('addRecipesToCart', [{ uid, portionCount }])
    },
    removeRecipe(recipeUid) {
      tracker.track({
        event: 'checkoutDeleteRecipe',
        checkoutDeleteRecipeName: this.recipe.name
      })
      store.dispatch('removeFromCart', { recipeUid })
    }
  }
}
</script>
